import type { NodeList, NodeCue, Cue } from "~/types/node";
import { VTTCue } from "captions";
import { v4 as uuidv4 } from "uuid";
const SUPPORTED_FILE_EXTENSIONS = [
  "srt",
  "vtt",
  "sbv",
  "sub",
  "ass",
  "ssa",
] as const;

const SUPPORTED_DOWNLOAD_FILE_EXTENSIONS = {
  srt: "srt",
  vtt: "vtt",
  sbv: "sbv",
  sub: "sub",
  txt: "txt",
} as const;

export const useSubtitleFile = () => {
  const fileName = useState<string>("fileName", () => "Untitled");
  const subtitles = useState<NodeList>("subtitles", () => []);
  const subtitlesChanged = useState<number>("subtitlesChanged", () => 0);
  const { $editsub, $device } = useNuxtApp();
  const { saveSubtitle } = useSubtitleStore();

  const { t } = useI18n();

  const isDesktop = computed(() => $device.isDesktop);

  const currentSubtitleFile = useState<File | null>(
    "currentSubtitleFile",
    () => null
  );

  const hasUnsavedChanges = useState<boolean>("hasUnsavedChanges", () => false);
  const hasAutoTranslate = useState<boolean>("hasAutoTranslate", () => false);
  const isSaved = useState<boolean>("isSaved", () => false);

  const setSubtitleFile = (file: File) => {
    currentSubtitleFile.value = file;
  };

  const clearSubtitleFile = () => {
    clearNuxtState([
      "subtitles",
      "fileName",
      "hasAutoTranslate",
      "hasUnsavedChanges",
      "currentSubtitleFile",
    ]);
  };

  const isValidSubtitleFile = (file: File): boolean => {
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    const isSupportedExtension = fileExtension
      ? SUPPORTED_FILE_EXTENSIONS.includes(fileExtension as any)
      : false;
    const isFileSizeValid = file.size <= 10 * 1024 * 1024; // 10MB
    return isSupportedExtension && isFileSizeValid;
  };

  const readFileAsString = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          resolve(event.target.result as string);
        } else {
          reject(new Error("Cannot read file"));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const getFileName = (): string => {
    return (
      currentSubtitleFile.value?.name.split(".").slice(0, -1).join(".") ||
      "Untitled"
    );
  };

  const setUnsavedChanges = (value: boolean) => {
    hasUnsavedChanges.value = value;
    if (value) {
      isSaved.value = false;
    } else {
      isSaved.value = true;
    }
  };

  // add new function to mark changes as saved
  const markChangesSaved = () => {
    setUnsavedChanges(false);
    isSaved.value = true;
  };

  const getExtension = (file?: File): string => {
    return (
      (file || currentSubtitleFile.value)?.name
        .split(".")
        .pop()
        ?.toLowerCase() || ""
    );
  };

  const getDuration = (): number => {
    if (subtitles?.value?.length > 0) {
      const lastSubtitle = subtitles.value[subtitles.value.length - 1];
      if (lastSubtitle.data && typeof lastSubtitle.data === "object") {
        return lastSubtitle.data.endTime * 1000;
      }
    }
    return 0;
  };

  const createNewFile = () => {
    const id = uuidv4();
    navigateTo(`/edit/${id}`);
  };

  const openSubtitleFile = () => {
    return new Promise((resolve, reject) => {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      if (isDesktop.value) {
        fileInput.accept = SUPPORTED_FILE_EXTENSIONS.map(
          (ext: string) => `.${ext}`
        ).join(",");
      }

      fileInput.onchange = async (event) => {
        const file: File | null =
          (event.target as HTMLInputElement).files?.[0] ?? null;

        try {
          resolve(await parseSubtitleFromFile(file));
        } catch (error: any) {
          reject(error);
        }
      };

      fileInput.click();
    });
  };

  const parseSubtitleFromFile = async (file: File | null): Promise<string> => {
    if (file && isValidSubtitleFile(file)) {
      try {
        setSubtitleFile(file);
        const content = await readFileAsString(file);
        const rawSubtitles = await $editsub.parseSync(content, getExtension());
        const subtitles = rawSubtitles.filter((node: Node) =>
          ["cue"].includes(node.type)
        );
        const uuid = uuidv4();

        await saveSubtitle({
          id: uuid,
          title: getFileName(),
          createdAt: new Date(),
          lastUpdatedAt: new Date(),
          hasAutoTranslate: false,
          data: JSON.parse(JSON.stringify(subtitles)),
        });

        return uuid;
      } catch (error: any) {
        const wrapErr = new Error(t("errors.invalidFile"));
        wrapErr.cause = error.message;
        throw wrapErr;
      }
    } else {
      const wrapErr = new Error(t("errors.invalidFileFormat"));
      wrapErr.cause = t("common.supportedFormats", {
        formats: SUPPORTED_FILE_EXTENSIONS.map((s) => `.${s}`).join(", "),
      });
      throw wrapErr;
    }
  };

  return {
    subtitles,
    currentSubtitleFile,
    SUPPORTED_FILE_EXTENSIONS,
    SUPPORTED_DOWNLOAD_FILE_EXTENSIONS,
    setSubtitleFile,
    clearSubtitleFile,
    isValidSubtitleFile,
    readFileAsString,
    hasUnsavedChanges,
    setUnsavedChanges,
    markChangesSaved,
    getDuration,
    hasAutoTranslate,
    fileName,
    isSaved,
    getExtension,
    createNewFile,
    openSubtitleFile,
    parseSubtitleFromFile,
    subtitlesChanged,
  };
};
